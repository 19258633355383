import React, { useState } from 'react';
import styles from './NavAreaCliente.module.css';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Sair } from '../../assets/sair.svg';
import { useUserContext } from '../../UserContextLogin';
import { ReactComponent as SetaDireita } from '../../assets/seta-direita.svg';
import { ReactComponent as SetaEsquerda } from '../../assets/seta-esquerda.svg';

const NavAreaCliente = () => {
  const location = useLocation();
  const { pathname } = location;
  const { userLogout } = useUserContext();
  const [menuOpen, setMenuOpen] = useState(false);

  function handleSair() {
    userLogout();
  }

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  function handleMenuItemClick() {
    setMenuOpen(false); // Fecha o menu ao clicar em um item
  }

  return (
    <>
      <button className={styles.menuToggle} onClick={toggleMenu}>
        {menuOpen ? (
          <SetaEsquerda />
        ) : (
          <div className={styles.botaoMenuNav}>
            <p>menu</p>
            <SetaDireita />
          </div>
        )}
      </button>
      <nav className={`${styles.menu} ${menuOpen ? styles.open : ''}`}>
        <div className={styles.divTitulo}>
          <h1>Bem-vindo</h1>
          <h1>
            <span>CLIENTE</span>
          </h1>
          <span className={styles.sep}></span>
          {menuOpen && (
            <div
              className={styles.menuCloseButton}
              onClick={() => setMenuOpen(false)}
            >
              <SetaEsquerda />
            </div>
          )}
        </div>
        {!menuOpen && (
          <button
            className={styles.menuOpenButton}
            onClick={() => setMenuOpen(true)}
          >
            <SetaDireita />
          </button>
        )}
        )
        <div className={styles.divMenu}>
          {[
            'desbloqueio',
            'pagamento',
            'alterar-senha',
            'manuais',
            'links',
          ].map((item) => (
            <div className={styles.divItem} key={item}>
              <span className={styles.marc}></span>
              <Link
                to={item}
                className={
                  pathname.includes(item) ? styles.selected : styles.itemMenu
                }
                onClick={handleMenuItemClick}
              >
                {item.charAt(0).toUpperCase() + item.slice(1)}
              </Link>
            </div>
          ))}
        </div>
        <div className={styles.sair} onClick={handleSair}>
          <Sair className={styles.svg} />
          <p>Sair</p>
        </div>
      </nav>
    </>
  );
};

export default NavAreaCliente;
