import React from 'react';
import styles from './Manuais.module.css';

const Manuais = () => {
  return (
    <div className={`${styles.containerMain} animeLeft`}>
      <h3>Manuais do sistema</h3>
      <div className={styles.modulos}>
        <h4>Módulo atendimento</h4>
        <div className={styles.Glinks}>
          <a
            href="https://konsistfiles.s3.sa-east-1.amazonaws.com/Manual+-+Atendimento.pdf"
            target="blank"
            className={styles.links}
          >
            Manual - Atendimento
          </a>
          <a
            href="https://konsistfiles.s3.sa-east-1.amazonaws.com/Manual+-+Atendimento+(Médicos).pdf"
            target="blank"
            className={styles.links}
          >
            Manual - Atendimento (Médicos)
          </a>
          <a
            href="https://konsistfiles.s3.sa-east-1.amazonaws.com/Manual+-+Atendimento+(Fechamento+de+Caixa).pdf"
            target="blank"
            className={styles.links}
          >
            Manual - Atendimento (Fechamento de Caixa)
          </a>
        </div>
      </div>
      <div className={styles.modulos}>
        <h4>Módulo faturamento</h4>
        <div className={styles.Glinks}>
          <a
            href="https://konsistfiles.s3.sa-east-1.amazonaws.com/Manual+-+Faturamento+(Fluxograma+do+faturamento).pdf"
            target="blank"
            className={styles.links}
          >
            Manual - Faturamento (Fluxograma do faturamento)
          </a>
          <a
            href="https://konsistfiles.s3.sa-east-1.amazonaws.com/Manual+-+Faturamento+(Alteração+do+Tipo+de+Guia).pdf"
            target="blank"
            className={styles.links}
          >
            Manual - Faturamento (Alteração do Tipo de Guia)
          </a>
          <a
            href="https://konsistfiles.s3.sa-east-1.amazonaws.com/Manual+-+Faturamento+(Cadastro+de+convênios).pdf"
            target="blank"
            className={styles.links}
          >
            Manual - Faturamento (Cadastro de convênios)
          </a>
          <a
            href="https://konsistfiles.s3.sa-east-1.amazonaws.com/Manual+-+Faturamento+(Faturas+enviadas).pdf"
            target="blank"
            className={styles.links}
          >
            Manual - Faturamento (Faturas enviadas)
          </a>
          <a
            href="https://konsistfiles.s3.sa-east-1.amazonaws.com/Manual+-+Faturamento+(Guias+digitadas).pdf"
            target="blank"
            className={styles.links}
          >
            Manual - Faturamento (Guias digitadas)
          </a>
          <a
            href="https://konsistfiles.s3.sa-east-1.amazonaws.com/Manual+-+Faturamento+(Importação+de+tabelas).pdf"
            target="blank"
            className={styles.links}
          >
            Manual - Faturamento (Importação de tabelas)
          </a>
          <a
            href="https://konsistfiles.s3.sa-east-1.amazonaws.com/Manual+-+Faturamento+(Recuperação+de+Glosa).pdf"
            target="blank"
            className={styles.links}
          >
            Manual - Faturamento (Recuperação de Glosa)
          </a>
          <a
            href="https://konsistfiles.s3.sa-east-1.amazonaws.com/Manual+-+Faturamento+(Repasse+-+Criando+regras).pdf"
            target="blank"
            className={styles.links}
          >
            Manual - Faturamento (Repasse - Criando regras)
          </a>
        </div>
      </div>
      <div className={styles.modulos}>
        <h4>Módulo financeiro</h4>
        <div className={styles.Glinks}>
          <a
            href="https://konsistfiles.s3.sa-east-1.amazonaws.com/Manual+-+Financeiro.pdf"
            target="blank"
            className={styles.links}
          >
            Manual - Financeiro
          </a>
        </div>
      </div>
      <div className={styles.modulos}>
        <h4>Módulo estoque</h4>
        <div className={styles.Glinks}>
          <a
            href="https://konsistfiles.s3.sa-east-1.amazonaws.com/Manual+-+Estoque.pdf"
            target="blank"
            className={styles.links}
          >
            Manual - Estoque
          </a>
        </div>
      </div>
    </div>
  );
};

export default Manuais;
